.list li {
  margin: 0.75rem 0;
}

.info {
  color: rgb(0, 49, 0);
  /* background-color: rgb(230, 246, 230); */
  padding: 1rem;
  border-radius: 4px;
}

.accordion {
  width: 100%;
  /* border-top: 1px solid #ccc; */
  margin-top: 1rem;
  /* box-shadow: 0px 4px 20px 0px rgba(29, 29, 29, 0.12); */
  /* padding: 0px 10px; */
  border-radius: 20px;
}

.item {
  width: 100%;
  /* border-bottom: 1px solid #ccc; */
  background-color: #fff;
  border-radius: 22px;
  box-shadow: 0px 4px 20px 0px rgba(29, 29, 29, 0.12);
  margin-bottom: 10px;
}

.itemBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  /* padding: 1rem; */
  font-size: 1rem;
  text-align: left;
  color: #424242;
  background-color: transparent;
  border: none;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  padding: 0px 10px;
  border-radius: 20px;
  padding-right: 20px;
}

.itemBtn:hover {
  /* background-color: #f3f3f3; */
}

.itemBtnExpanded {
  /* background-color: #e7e7e7; */
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  /* box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3); */
  border-bottom: 1px solid #d1d1d1;
}
.itemBtnExpanded::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  padding: 20px 30px;
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
  font-size: 24px;
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}

/* ----------------------------------------- */
