.skillcard-bg-image {
  display: flex;
  width: 334.327px;
  height: 92px;
  padding: 21px 23.327px 21px 16px;
  align-items: center;
  gap: 18px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #b2b2b2;
  background: #fff;
  transition: all 0.3s linear;
}

.skillcard-bg-image:hover {
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  /* transition property is already specified in the base class, no need to repeat it here */
  /* background-color: #7D66BB; */
  border: none;
}

/* The following block should not be repeated */

.skillcard-bg-image:hover .skill-card-main-text {
  color: #7D66BB !important;
  /* transition property is already specified in the base class, no need to repeat it here */
}

.skillcard-bg-image:hover .skill-icon-div-bg {
  background-color: #55418c4a !important;
}

.skillcard-bg-image:hover .skill-icon-div-bg svg {
  fill: rgba(85, 65, 140, 0.66) !important;
  /* transition property is already specified in the base class, no need to repeat it here */
}

/* Remove the commented out block */

.skill-icon-div-bg {
  /* transition property is not needed here, it's already specified in the base class */
  display: flex;
  width: 50px;
  height: 50px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  background: #e6e6e6;
}

.skill-icon-div-bg svg {
  fill: #545454;
}

.skil-card-head-div {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.skill-card-main-text {
  color: #424242;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.9%;
  /* 29.458px */
}

/* Add the media query here */

@media screen and (max-width: 576px) {
  .skil-card-head-div {
    gap: 6px;
  }

  .skillcard-bg-image {
    display: flex;
    padding: 5px 8.726px 5px 4px;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    border: 0.5px solid #B2B2B2;
    background: #FFF;
    width: 167px;
    height: 49px;
    /* fill property is not applicable to .skillcard-bg-image, remove it */
  }

  .skill-icon-div-bg {
    height: 38px;
    width: 38px;
  }

  .skill-card-main-text {
    color: #424242;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 133.9%;
    /* 12.051px */
  }
}