.about-section-line {
  position: absolute;
  left: 0;
  bottom: 0;
}

.about-section-arrow-icon {
  position: absolute;
  right: 145px;
  bottom: 107px;
}

.about-main-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 70px;
  align-items: flex-start;
}

.about-images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-images-div-1 {
  display: grid;
  grid-template-columns: 8fr 4fr;
}

.about-images-div-2 {
  display: grid;
  grid-template-columns: 4fr 8fr;
}

.about-images-div-3 {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr;
}

.about-images-1 {
  width: 100%;
  min-height: 110px;
  border-radius: 120px;
}

.about-images-2 {
  width: 100%;
  min-height: 110px;
  border-radius: 50%;
}

.about-images-2 img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 50%;
}

.about-images-3 {
  width: 100%;
  min-height: 110px;
  background-color: rgba(199, 179, 255, 1);
  border-radius: 120px;
}

.about-images-4 {
  width: 100%;
  min-height: 110px;
  border-radius: 120px;
}

.about-images-4 img {
  object-fit: cover;
}

.about-images-5 {
  width: 100%;
  min-height: 110px;
  border-radius: 50%;
}

.about-images-6 {
  width: 100%;
  min-height: 110px;
  background-color: rgba(167, 197, 253, 1);
  border-radius: 120px;
}

.about-images-7 {
  width: 100%;
  min-height: 110px;
  background-color: rgba(199, 179, 255, 1);
  border-radius: 120px;
}

.about-section-content p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 150.9%;
}

.user-images-div {
  background-image: url("../../../../public/images/user-about-card.jpg");
  height: 338px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-position: bottom;
  bottom: 50px;
}

/* height 276px
width : 90%
*/
.about-card-user-box {
  /* background-color: rgb(255, 255, 255); */
  border-radius: 100px;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
}

.about-card-user-box-image {
  width: 100%;
  border-radius: 100px;
}

.about-images-container-content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  margin: 40px;
  margin-top: 20px;
}

.about-icons-div {
  display: flex;
  align-items: center;
  gap: 35px;
}

.about-icon {
  width: 30px;
  height: 30px;
  color: #545454;
}

.about-section-content-mobile {
  display: block;
}

.about-section-content {
  display: none;
}

.mobile-about-read-more {
  transition: all 0.5s linear;
}

.about-section-content-mobile p {
  line-height: 24px;
}

.about-user-image-person {
  width: 170px;
}

.about-link-a-item:hover{
  transform: scale(1.4);
  transition: all 0.2s linear;
}
.about-link-a-item{
  transition: all 0.2s linear;
  transform-origin: bottom center;
}

/* about section css from laptop */
@media screen and (min-width: 1024px) {
  .about-main-container {
    grid-template-columns: 5fr 7fr;
  }

  .about-images-container {
    display: flex;
    flex-direction: column;
  }

  .about-images-div-1 {
    display: grid;
    grid-template-columns: 8fr 4fr;
  }

  .about-images-div-2 {
    display: grid;
    grid-template-columns: 4fr 8fr;
  }

  .about-images-div-3 {
    display: grid;
    grid-template-columns: 4fr 4fr 4fr;
  }

  .about-images-1 {
    width: 100%;
    height: 240px;
    border-radius: 120px;
  }

  .about-images-2 {
    width: 241px;
    height: 240px;
    border-radius: 50%;
  }

  .about-images-3 {
    width: 241px;
    height: 190px;
    border-radius: 120px;
  }

  .about-images-4 {
    width: 100%;
    height: 190px;
    border-radius: 120px;
  }

  .about-images-5 {
    width: 240px;
    height: 240px;
    border-radius: 50%;
  }

  .about-images-6 {
    width: 100%;
    height: 240px;
    border-radius: 120px;
  }

  .about-images-7 {
    width: 100%;
    height: 240px;
    border-radius: 120px;
  }

  .about-card-user-box {
    width: 80% !important;
    height: 595px;
  }

  .about-section-content p {
    font-size: 18px;
  }

  .about-section-content-mobile {
    display: none;
  }

  .about-section-content {
    display: block;
  }

  .about-section-content p {
    line-height: 30px;
  }
}

@media screen and (max-width: 991px) {
  .about-card-user-box {
    width: 401px;
  }
}

@media screen and (max-width: 576px) {
  .about-card-user-box {
    width: 100%;
  }
}