.home-blog-section-main-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px;
}

.home-blog-button-section {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.home-blog-section-link-button {
  text-decoration: none;
}



@media screen and (max-width: 991px) {
  .home-blog-section-main-div {
    grid-template-columns: 1fr;
    gap: 30px;
  }

}


@media screen and (max-width : 576px) {
  .home-blog-section-main-div{
    gap: 18px;
  }
}