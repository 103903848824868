/* .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
  }
  
  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  } */
  .certificate-card-main-grid {
    display: flex;
    flex-direction: column;
    height: 570px;
    flex-wrap: wrap;
    gap: 20px;
    overflow: auto;
    padding: 20px;
  }
.certificate-slide-div {
  display: flex;
  flex-direction: column;
  height: 600px;
  
}
.certificate-card-main-div {
  width: 650px !important;
  display: flex;
  gap: 20px;
  border-radius: 34px;
  background: #fff;
  align-items: center;
  padding: 24px;
   /* box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25); */
height: 157px;
}
.certificate-imag-div {
  width: 160px;
  object-fit: cover;
}

.certificate-imag-div img {
  width: 100%;
}
.certificate-card-content-box{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.certificate-card-content-box h1 {
  color: #424242;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.certificate-card-content-box p {
  color: #424242;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.certificate-card-content-box span {
  color: #7d66bb;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}




@media screen and (max-width : 576px) {
  .certificate-card-main-grid{
    height: 474px;
  }
  .certificate-imag-div{
    width: 90px;
  }
  .certificate-card-main-div{
    height: 124px;
    width: 100% !important;
    padding: 14px;
    border-radius: 20px;
    gap: 10px;
  }
  .certificate-card-content-box h1 {
    font-size: 14px;
  }
  .certificate-card-content-box p{
    font-size: 12px;
  }
  .certificate-card-content-box span{
    font-size: 12px;
  }
}