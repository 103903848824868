.refresh {
  padding: 5px;
  position: absolute;
  border: 1px dotted var(--accent);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.refresh path {
  fill: var(--accent);
}

.box {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--accent);
  margin: 0;
}

.mobile-main-nav-div {
  display: none;
}

.nav-mobile-logo {
  width: 100px;
}

.mobile-nav-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 18px;
  transform: translateX(100%);
  will-change: transform;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 34px 0px 0px 34px;
  background: linear-gradient(178deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 0.01%, rgba(255, 255, 255, 0.20) 100%);
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(10px);
  padding-left: 20px;
  align-items: center;
  padding-right: 110px;
}

.header-looking-hr-line {
  background-image: linear-gradient(180deg, #FDFDFD 0%, rgba(253, 253, 253, 0.00) 100%);
}

.header-close-icon {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(125, 102, 187, 0.73);
  box-shadow: drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.20));
  position: absolute;
  left: -18px;
  top: 37px;
}

.mobile-nav-menu a {
  line-height: normal;
  transition: 0.25s ease-out;
  padding: 10px 20px;
  border-radius: 99px;
  text-decoration: none;
  color: #5C5C5C;
  text-align: left;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100.4%;
  /* 15.06px */
}

.header-mobile-looking-para {
  width: 80%;
  color: #7D66BB;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.9%;
  /* 14.09px */
}

.mobile-nav-menu li {
  color: var(--background);
  display: block;
  transform-origin: -20px 50%;
  font-weight: bold;
  font-size: 48px;
  padding: 10px;
  will-change: transform, opacity, filter;
}

.mobile-nav-menu ul,
li {
  list-style: none;
  margin: 0;
}

.mobile-menu-button {

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: 38px;
  right: 27px;
  z-index: 9;
  display: flex;
  width: 39px;
  height: 39px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(125, 102, 187, 0.73);
  outline: none;
  border: none;
}

.mobile-menu-button path {
  fill: #fff;
}

input {
  accent-color: var(--accent);
}

.controls {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-bottom: 50px;
  align-items: center;
}

label {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

input[type="number"] {
  border: 0;
  border-bottom: 1px dotted var(--accent);
  color: var(--accent);
  margin-left: 10px;
  background: var(--background);
  width: 50px;
}

input[type="number"]:focus {
  outline: none;
  border-bottom: 2px solid var(--accent);
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.example {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.item {
  width: 300px;
  height: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: var(--accent);
  color: var(--background);
  font-size: 240px;
  line-height: 260px;
  font-weight: 700;
  border-radius: 30px;
}

/* large screen header */

.large-header-main-div {
  grid-template-columns: 1fr 11fr;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0;
  display: flex;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease;
  z-index: 10;
}

.large-screen-header-logo {
  padding-left: 30px;
  width: 100px;
}

.large-screen-header-logo img {
  width: 100%;
}

.large-header-main-div.shadow {
  /* box-shadow: 0 9px 9px -9px rgba(0, 0, 0, 0.13); */
}

.large-header-main-div.hidden {
  transform: translateY(-110%);
}

.large-header-main-div-items {
  border-radius: 34px;
  background: #f0f0f0;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
}

.large-header-main-div-items {
  padding-right: 150px;
  display: flex;
  justify-content: space-around;
  position: relative;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
  gap: 25px;
}

.large-header-main-div-items a {
  color: #424242;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.6s linear;
  padding: 10px 20px;
  border-radius: 99px;
  text-decoration: none;
}

.large-header-main-div-items a:hover {
  background-color: #daeaff;
}

.header-active-tab {
  /* background-color: #daeaff; */
  z-index: 1;
  border-radius: 99px;
  transition: all 0.6s linear;
  /* color: #185ee0; */
}

@media screen and (max-width: 1023px) {
  .mobile-main-nav-div {
    display: block;
  }

  .large-header-main-div {
    display: none;
  }
}

/* new header */
.header-new-tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
  gap: 25px;
}

.header-new-tabs * {
  z-index: 2;
}

.header-container input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 18px;
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  top: 10px;
  left: 30%;
  font-size: 10px;
  margin-left: 0.75rem;
  border-radius: 50%;
  margin: 0px;
  background-color: #e6eef9;
  transition: 0.15s ease-in;
}

.header-container input[type="radio"]:checked+label {
  color: #185ee0;
}

.header-container input[type="radio"]:checked+label>.notification {
  background-color: #185ee0;
  color: #fff;
  margin: 0px;
}

.header-container input[id="radio-1"]:checked~.glider {
  transform: translateX(0);
  width: 300px;
}

.header-container input[id="radio-2"]:checked~.glider {
  transform: translateX(100%);
  width: 300px;
}

.header-container input[id="radio-3"]:checked~.glider {
  width: 300px;
  transform: translateX(200%);
}

.header-container input[id="radio-4"]:checked~.glider {
  transform: translateX(300%);
}

.header-container input[id="radio-5"]:checked~.glider {
  transform: translateX(400%);
}

.header-container input[id="radio-6"]:checked~.glider {
  transform: translateX(500%);
}

.header-container input[id="radio-7"]:checked~.glider {
  transform: translateX(600%);
}

.header-container input[id="radio-8"]:checked~.glider {
  transform: translateX(700%);
}

.glider {
  position: absolute;
  display: flex;
  height: 30px;
  width: 50px;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
  top: 33px;
}

@media (max-width: 700px) {
  .header-new-tabs {
    transform: scale(0.6);
  }
}