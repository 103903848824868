.home-blog-card-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 34px;
  background-color: #f9f9f9;
  height: 496px;
}

.home-blog-card-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
}
.home-blog-card-image-div {
  width: 100%;
  height: 240px;
}
.home-blog-card-image {
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 25px;
  display: block;
}
.home-blog-head-heading {
  color: #424242;
  font-size: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
}
.home-blog-love-icon-span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f24e1e;
}
.home-blog-description-para {
  color: #343434;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home-blog-card-date-text {
  color: #aaa;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.home-blog-para-read-more-text-link {
  text-decoration: none;
}
.home-blog-para-read-more-text {
  color: #0038ff;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}
.home-blog-card-date-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-blog-card-date-and-heart-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
.home-blog-card-dot-icon {
  font-size: 28px;
  color: #aaa;
}
.home-blog-card-heart-icon {
  color: red;
  font-size: 16px;
  cursor: pointer;
}

.home-blog-card-heading-2 {
  color: #424242;
  font-size: 24px;
  font-weight: 800;
  line-height: 133.9%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 991px) {
  .home-blog-section-main-div {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .home-blog-card-main-div {
    display: grid;
    grid-template-columns: 4fr 8fr;
    height: 100%;
  }
}

@media screen and (max-width: 576px) {
  .home-blog-card-image-div {
    height: 100%;
  }
  .home-blog-card-image {
    border-radius: 8px;
  }
  .home-blog-card-heading-2 {
    font-size: 14px;
    -webkit-line-clamp: 2;
    margin-bottom: 6px;
  }
  .home-blog-description-para {
    font-size: 12px;
    line-height: 14px;
    -webkit-line-clamp: 2;
  }
  .home-blog-card-main-div {
    height: auto;
    border-radius: 8px;
  }
  .text-light-color {
    font-size: 10px;
  }
  .home-blog-para-read-more-text {
    font-size: 10px;
  }
  .home-blog-card-content-container {
    gap: 0px;
  }
  .home-blog-card-content-container {
    padding: 8px;
  }
}
