.blog-details-main-head-container {
  border-radius: 10px;
  height: 180px;
  background-image: linear-gradient(rgba(95, 56, 171, 1), rgb(255, 255, 255));
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.blog-details-main-head-banner {
  color: #fff;
  text-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  font-size: 80px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
}

.blog-details-image-div {
  width: 100%;
}

.blog-details-image {
  width: 100%;
  border-radius: 34px;
}

.blog-details-big-para-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-details-width-less-main {
  display: flex;
  justify-content: center;
}

.blog-details-width-less {
  width: 80%;
}



@media screen and (min-width: 1024px) {
  .blog-details-main-head-container {
    height: 384px;
    margin-top: 50px;
  }
  .blog-details-big-para-div p {
    font-size: 24px;
  }

  .blog-details-main-head-banner {
    color: #fff;
    text-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
    font-size: 200px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
  }

  .blog-details-big-para-div {
    gap: 30px;
  }
}

@media screen and (max-width : 576px) {
  .blog-details-main-head-container {
    margin-top: 0px;
  }

  .blog-details-main-head-container-blogs {
    margin-bottom: 40px;
  }
}