.subscribe-card-background-image-div {
  background-image: url("../../../../public/images/subscribe-bg.png");
  height: 543px;
  width: 1244px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-position: bottom;
  background-repeat: no-repeat;
  gap: 30px;
  background-size: cover;
  border-radius: 40px;
  padding: 100px 0px;
}

.sebscribe-card-main-flex-div {
  display: flex;
  justify-content: center;
}

.subscribe-card-background-image-div h1 {
  color: #fff;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 133.9%;
}

.subscribe-card-background-image-div p {
  color: #5b5757;
  text-align: center;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.9%;
  width: 80%;
}

.subscribe-input-class-div {
  width: 725px;
  height: 89px;
  display: flex;
  background-color: #fff;
  align-items: center;
}

.subscribe-input-class {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  padding-left: 20px;
  font-weight: 600;
}

.subscribe-input-class-div button {
  border: none;
  background-color: transparent;
}

.subscribe-input-send-icon {
  color: #7773ae;
  font-size: 40px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thank-for-subscribg-text{
  text-align: center;
}
.subscribe-error-text{
  font-size: 24px !important;
  color: red !important;
}

@media screen and (max-width: 991px) {
  .subscribe-input-class-div {
    width: 70%;
  }

  .subscribe-card-background-image-div {
    height: 370px;
    border-radius: 24px;
  }

  .subscribe-card-background-image-div h1 {
    font-size: 36px;
  }

  .subscribe-card-background-image-div p {
    font-size: 26px;
  }

  .subscribe-card-background-image-div p {
    height: 76px;
  }
}

@media screen and (max-width: 576px) {
  .subscribe-card-background-image-div {
    padding: 40px 0px;
    border-radius: 10px;
    gap: 12px;
    height: 134px;
  }

  .subscribe-card-background-image-div h1 {
    font-size: 16px;
  }

  .subscribe-card-background-image-div p {
    font-size: 10px;
    width: 80%;
  }

  .subscribe-input-class-div {
    width: 207px;
    height: 28.3px;
  }

  .subscribe-input-class-div {
    height: 40px;
  }

  .subscribe-input-class {
    font-size: 12px;
    font-weight: normal;
    height: 20px;
    padding-left: 10px;
  }

  .subscribe-input-send-icon {
    font-size: 10px;
    margin-right: 5px;
  }


}