/* .education-component-header {
  display: grid;
  grid-template-columns: 1fr 11fr;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background: #fff;
  padding: 40px;
  transition: all 0.6s linear;
}
.education-component-header:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  transition: all 0.6s linear;
  border: none;
}
.education-logo {
  width: 160px;
}
.education-font-div {
  border-left: 2px solid #b3b3b3;
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-left: 40px;
  margin-left: 40px;
}
.education-font-div div {
  display: grid;
  grid-template-columns: 8fr 4fr;
}
.education-head-1 {
  color: #424242;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}
.education-head-2 {
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.9%;
}
.education-head-3 {
  color: #343434;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.education-para {
  color: #787878;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  line-height: 35px;
}

@media screen and (max-width: 991px) {
  .education-head-1 {
    font-size: 22px;
  }
  .education-head-2 {
    font-size: 18px;
  }
  .education-para {
    font-size: 18px;
    line-height: 26px;
  }
  .education-logo {
    width: 120px;
  }
  .education-head-3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {
  .education-component-header {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .education-font-div {
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
    border-top: 1px solid #b3b3b3;
    padding-top: 30px;
    gap: 10px;
  }
  .education-img-div {
    display: flex;
    justify-content: center;
  }
  .education-font-div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .education-head-1 {
    font-size: 20px;
  }
  .education-head-2 {
    font-size: 16px;
  }
  .education-head-3 {
    font-size: 14px;
  }
  .education-para {
    font-size: 16px;
    justify-content: center;
  }
} */

/* ----------------------------------------------------------------------------------- */

.eaducation-component-card-div {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 22px;
  position: relative;
  transition: all 0.4s linear;
}
.eaducation-component-card-div:hover {
  transition: all 0.4s linear;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.education-unvercity-img-div {
  height: 200px;
}
.education-college-image {
  width: 100%;
  height: 100%;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.education-img-div {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  position: absolute;
  top: 18%;
  left: 46px;
  background-color: #fff;
  padding: 8px;
  border-radius: 20px;
  height: 160px;
  width: 160px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.education-logo {
  width: 100%;
}
.education-font-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 80px 0px 50px 100px;
}

.education-head-1 {
  color: #424242;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.education-head-2 {
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.9%;
}
.education-head-3 {
  color: #343434;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.education-para {
  color: #787878;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

/* ------------------------- tab screen --------------------------------- */
@media screen and (max-width: 1023px) {
  .education-unvercity-img-div {
    height: 150px;
  }
  .education-img-div {
    width: 120px;
    top: 14%;
    left: 30px;
    height: auto;
  }
  .education-logo {
    width: 100%;
  }
  .education-font-div {
    padding: 50px 20px 50px 57px;
  }
  .education-head-1 {
    font-size: 18px;
  }
  .education-head-2 {
    font-size: 16px;
  }
  .education-head-3 {
    font-size: 16px;
  }
  .education-para {
    font-size: 16px;

    line-height: 24px;
  }
}
/* ------------------------- mobile screen --------------------------------- */
@media screen and (max-width: 576px) {
  .education-unvercity-img-div {
    height: 120px;
  }
  .education-img-div {
    width: 120px;
    top: 12%;
    left: 30px;
  }
  .education-logo {
    width: 100%;
  }
  .education-font-div {
    padding: 50px 20px 50px 57px;
  }
  .education-head-1 {
    font-size: 18px;
  }
  .education-head-2 {
    font-size: 16px;
  }
  .education-head-3 {
    font-size: 16px;
  }
  .education-para {
    font-size: 16px;

    line-height: 20px;
  }
}
