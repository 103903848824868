.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  padding: 0px;
  margin: 0px;
}

.main-width-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 120px 0px;
}

.middle-width-container {
  width: 90%;
}

.main-head-color {
  color: #424242;
}

.main-head {
  color: #424242;
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
}

.heading-1 {
  color: #424242;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}

.heading-2 {
  color: #424242;
  font-size: 28px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
}

.heading-3 {
  color: #343434;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.primary-para {
  color: #343434;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.9%;
}

.secondary-para {
  color: #343434;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.text-light-color {
  color: #aaa;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.loader-class {
  width: 100%;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.outline-button {
  display: flex;
  width: 201px;
  height: 62px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  border: 1px solid #7d66bb;
  background: #fff;
  transition: all 0.6s linear;
  color: #7d66bb;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.outline-button:hover {
  background: #f5f5f5;
  box-shadow: 0px 24px 54px -8px #9981d5;
  outline: none;
  border: none;
  transition: all 0.9s linear;
}

.outline-button-2 {
  display: flex;
  width: 157px;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 52px;
  border: 1px solid #7d66bb;
  background-color: #fff;
  color: #7d66bb;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.6s linear;
  cursor: pointer;
}

.outline-button-2:hover {
  background: #7d66bb;
  color: #fff;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  outline: none;
  border: none;
  transition: all 0.9s linear;
}

.color-button {
  display: flex;
  width: 201px;
  height: 64px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 52px;
  background: #9981d5;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
  border: none;
  transition: all 0.6s linear;
  cursor: pointer;
}

.color-button:hover {
  background: #7d66bb;
  box-shadow: 0px 24px 54px -8px rgba(0, 0, 0, 0.25);
  transition: all 0.6s linear;
}

.shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.4s linear;
}

.shadow:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: all 0.4s linear;
  transition: all 0.4s linear;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.socialmedia-icons-color {
  text-decoration: none;
  color: #707070;
  transition: all 0.3s linear;
}

.socialmedia-icons-color:hover {
  color: #9981d5;
  transition: all 0.3s linear;
}

@media screen and (max-width: 576px) {
  .heading-1 {
    font-size: 18px !important;
  }

  .heading-3 {
    font-size: 14px;
    line-height: 18px;
  }

  .primary-para {
    font-size: 14px;
  }

  .download-button-icon {
    font-size: 38px !important;
  }

  .color-button {
    width: 118px;
    height: 40px;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .outline-button {
    width: 118px;
    height: 40px;
    flex-shrink: 0;
    color: #7D66BB;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media screen and (max-width: 1023px) {
  .heading-1 {
    font-size: 28px;
  }
}

@media screen and (min-width: 1024px) {
  .height-100vh {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .middle-width-container {
    width: 90%;
  }

  .main-head {
    font-size: 64px;
    font-weight: 800;
    margin-top: 60px;
  }

  .secondary-para {
    font-size: 32px;
    font-weight: 500;
    line-height: 50px;
  }

  .outline-button {
    font-size: 24px;
    width: 257px;
    height: 79px;
  }

  .outline-button-2 {
    font-size: 24px;
    width: 257px;
    height: 79px;
  }

  .color-button {
    font-size: 24px;
    width: 257px;
    height: 79px;
  }
}

/* scroll bar styles */
*::-webkit-scrollbar {
  width: 8px !important;
  height: 6px;
}

*::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 3px #9981d5; */
}

*::-webkit-scrollbar-thumb {
  background-color: #9981d5 !important;
  /* outline: 1px solid #024f70; */
  border-radius: 8px !important;
}