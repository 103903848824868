    .footer-main-container {
        border-radius: 50px 50px 0px 0px;
        background: rgba(220, 220, 220, 0.34);
        /* width: 100vw; */
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .footer-mobile {
        display: none;
    }

    .footer-desktop {
        display: block;
    }

    .footer-questions-para {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150.4%;
        /* 12.864px */
    }

    .footer-div-container {
        display: grid;
        grid-template-columns: 4fr 3fr 3fr 3fr;
        justify-items: center;
    }




    .footer-img-logo {
        margin-bottom: 20px;
        width: 120px;
    }

    .footer-location-logo-para {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
    }

    .footer-social-media-logos {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .footer-div-container b {
        /* padding-top: 30px; */
    }

    .ul-items-div {
        padding-top: 30px;
    }

    .ul-items-div b {
        color: #424242;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 133.9%;
        /* 32.136px */
    }

    .ul-items-div ul li {
        color: #707070;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 109.9%;
        /* 17.584px */
        margin-top: 20px;
    }

    .footer-hr-line-color {
        /* width: 317px; */
        border-top: 1px solid;
        border-color: #DBDBDB;
    }

    .ul-items-div ul li a {
        text-decoration: none;
        color: #707070;
        transition: all 0.3s linear;
    }

    .ul-items-div ul li a:hover {
        color: #9981d5;
        transition: all 0.3s linear;
    }


    .footer-share-icons {
        fill: #545454
    }

    .footer-share-icons:hover {
        fill: #9981d5;
        transition: all 0.3s linear;
        cursor: pointer;
    }

    @media screen and (min-width:998px) {
        .footer-copy-text {
            width: 90vw;
            color: #707070;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 109.9%;
            display: flex;
            justify-content: end;
            padding-right: 60px;
            padding-top: 40px;
        }
    }

    @media screen and (max-width : 997px) {
        .footer-div-container {
            grid-template-columns: 8fr 4fr;
            justify-items: start;
            row-gap: 30px;
        }

        .footer-mobile {
            display: none;
        }

        .footer-desktop {
            display: block;
        }

        .footer-copy-text {
            width: 90vw;
            color: #707070;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 109.9%;
            display: flex;
            justify-content: end;
            padding-right: 60px;
            padding-top: 40px;
        }

    }

    @media screen and (max-width : 576px) {
        .footer-div-container {
            grid-template-columns: 5.5fr 1f 5.5fr;
            justify-items: start;
            row-gap: 30px;

        }

        .footer-mobile {
            display: block;
        }

        .footer-desktop {
            display: none;
        }

        .footer-questions-para {
            font-size: 14px;
        }

        .footer-location-logo-para p {
            font-size: 14px;
        }

        .ul-items-div b {
            font-size: 14px;
        }

        .ul-items-div ul li {
            font-size: 12px;
            line-height: 109%;
        }

        .footer-loho-div-class {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: flex-end;
            padding-bottom: 30px;
        }

        .footer-main-container {
            flex-direction: column;
            /* align-items: center00; */
            gap: 30px;
            padding-left: 30px;
        }

        .footer-social-media-logos {
            margin-top: 20px;
        }

        .footer-location-logo-para {
            /* margin-top: 20px; */
            margin: 0px;
        }

        .footer-copy-text {
            padding-top: 10px;
            color: #707070;
            font-family: Montserrat;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 109.9%;
            /* 10.99px */
        }

        .footer-hr-line {
            width: 85vw;
        }

        .footer-img-logo {
            width: 80px;
        }

        .footer-location-div {
            display: flex;
            align-items: center;
            margin-top: 25px;
            gap: 10px;
        }
    }