.blog-card-main-div {
  display: grid;
  grid-template-columns: 4fr 8fr;
  align-items: center;
  justify-content: center;
  border-radius: 34px;
  background-color: #f9f9f9;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 30px 0px;
}

.blog-card-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  padding: 20px;
  justify-content: space-between;
}

.blog-card-image-div {
  width: 100%;
}

.blog-card-image {
  width: 100%;
  border-radius: 25px;
  display: block;
}

.blog-head-heading {
  color: #424242;
  font-size: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
}

.blog-description-para {
  color: #343434;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
}

.blog-card-date-text {
  color: #aaa;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.blog-para-read-more-text-a {

  text-decoration: none !important;
}

.blog-para-read-more-text {
  color: #0038ff;
  font-size: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.blog-card-date-div {
  display: flex;
  justify-content: space-between;
}

.blog-card-date-and-heart-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.blog-card-dot-icon {
  font-size: 28px;
  color: #aaa;
}

.blog-card-heart-icon {
  color: red;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  .blog-card-main-div {
    flex-direction: row;
  }


  .blog-card-content-container {
    padding: 0;
    padding: 5px 20px 5px 20px;
  }
}