.professional-tab-card-active {
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s linear;
}
.professional-tab-card {
  width: 100%;
  height: 118.64px;
  display: flex;
  border-radius: 20px;
  /* align-items: center; */
  align-content: center;
  border: 1px solid #ececec;
  background: #fff;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.professional-tab-card:hover {
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s linear;
}
.professional-tab-card-img-div {
  width: 118px;
  /* height: 118px; */
}
.professional-tab-card-img {
  width: 100%;
}
.professional-tab-card-head {
  color: #424242;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}
.professional-tab-card-para {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.professional-tab-card-hr-line {
  border: 1px solid rgba(179, 179, 179, 1);
  margin-top: 15px;
  margin-bottom: 15px;
}

.professional-card-content-div {
  width: 324px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 25px;
}
