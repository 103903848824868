.section-number-div {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.home-banner-image-main-div {
  position: relative;
}

.home-banner-img-outline {
  width: 330px;
  height: 330px;
  border-radius: 50%;
  border: 1px solid rgba(217, 232, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.hello-hand-icon {
  -webkit-transition: -webkit-transform 0.2s;
}

.hello-hand-icon:hover {
  transform: scale(1);
  -webkit-transform: rotate(90deg) translateZ(0);
  margin-bottom: 9px;
}

.home-banner-img-outline-2 {
  width: 234px;
  height: 234px;
  border-radius: 50%;
  border: 1px solid rgba(217, 232, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner-image {
  border-radius: 100%;
  border: 30px solid rgba(217, 232, 255, 1);
  z-index: 5;
}

.home-banner-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-bottom: 40px;
}

.home-banner-image-icon {
  animation: animName 30s linear infinite;
  position: absolute;
  top: 0;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.home-svg-1 {
  position: absolute;
  top: -17px;
  left: 81px;
}

.home-svg-2 {
  position: absolute;
  top: 21px;
  left: 327px;
}

.home-svg-3 {
  position: absolute;
  top: 196px;
  right: -30px;
}

.home-svg-4 {
  position: absolute;
  /* top: -24px; */
  left: 290px;
  bottom: 12px;
}

.home-svg-5 {
  position: absolute;
  /* top: -24px; */
  left: 61px;
  bottom: 25px;
}

.home-svg-6 {
  position: absolute;
  top: 186px;
  left: -18px;
}

.home-banner-heading {
  font-size: 30px;
}

.home-banner-para {
  color: #d42924;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
}

.home-banner-buttons-div {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.download-button-icon {
  font-size: 34px;
  margin-right: 10px;
}

/* scrolling style */

.ms-header__title {
  flex: 1 1 100%;
  color: #424242;
  font-family: Montserrat;
  font-size: 74px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: start;
}

.home-main-icon-span {
  display: flex;
  align-items: flex-end;
  /* justify-content: center; */
}

.ms-slider {
  display: inline-block;
  height: 1.3em;
  overflow: hidden;
  margin-left: 20px;
  vertical-align: middle;
  /* -webkit-mask-image: linear-gradient(transparent, white, white, white, transparent);
          mask-image: linear-gradient(transparent, white, white, white, transparent); */
  mask-type: luminance;
  mask-mode: alpha;
}

.ms-slider-word-1 {
  color: #273cf4;
}

.ms-slider-word-2 {
  color: #9427f4;
}

.ms-slider-word-3 {
  color: #ffa500;
}

.ms-slider-word-3 {
  color: #ffa500;
}

.ms-slider-word-4 {
  color: red;
}

.ms-slider__words {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-animation-name: wordSlider;
  animation-name: wordSlider;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.ms-slider__word {
  display: block;
  line-height: 1.3em;
  text-align: left;
}

@-webkit-keyframes wordSlider {
  0% {
    transform: translateY(0%);
  }

  20% {
    transform: translateY(-20%);
  }

  40% {
    transform: translateY(-40%);
  }

  60% {
    transform: translateY(-60%);
  }

  80% {
    transform: translateY(-80%);
  }
}

@keyframes wordSlider {
  0% {
    transform: translateY(0%);
  }

  20% {
    transform: translateY(-20%);
  }

  40% {
    transform: translateY(-40%);
  }

  60% {
    transform: translateY(-60%);
  }

  80% {
    transform: translateY(-80%);
  }
}



/* profession main div */
.profession-main-div {
  background-color: #ebebeb;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  padding: 14px 0px;
  width: 100%;
  /* height: 100vh; */
}



@media screen and (min-width: 1024px) {
  .home-banner-img-outline {
    width: 450px;
    height: 450px;
  }

  .home-banner-img-outline-2 {
    width: 370px;
    height: 370px;
  }

  .home-banner-image {
    width: 330px;
    height: 330px;
    object-fit: cover;
  }

  .home-banner-div {
    text-align: left;
    height: 100vh;
  }

  .home-banner-div {
    flex-direction: row;
  }

  .home-banner-heading-div {
    order: -1;
    width: 60%;
  }

  .home-banner-heading {
    font-size: 80px;
    font-style: normal;
    font-weight: 800;
  }

  .home-banner-para {
    font-size: 25px;
  }

  .home-banner-buttons-div {
    justify-content: flex-start;
    gap: 60px;
  }

  .home-banner-image-icon {
    width: 452px;
    height: 452px;
  }

  .ms-header__title {
    text-align: start !important;
  }


  /* profession section */
  .profession-main-div {
    border-radius: 77px;
    height: 130vh;
  }
}

@media screen and (max-width:1023px) {
  .home-banner-image-icon {
    width: 330px;
    height: 330px;
    border-radius: 50%;
  }
  .home-banner-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .home-svg-4 {
    left: 207px;
    width: 57px;
  }

  .home-banner-div {
    padding-top: 0px;
  }

  .hello-hand-icon {
    width: 50px;
    height: 35px;

  }

  .ms-header__title {
    font-size: 45px;
    text-align: center;

  }

  .home-main-icon-span {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .hello-hand-icon {
    height: 50px;
  }

  .contacte-head-text {

    font-size: 34px;

  }
}

@media screen and (max-width:576px) {
  .ms-header__title {
    font-size: 30px;
  }

  .home-banner-image-icon {
    width: 295px;
    height: 295px;
    top: 22px;
    left: 22px;
  }

  .home-svg-4 {

    transform: rotate(367deg);
  }

  .download-button-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 3px;
  }

  .home-banner-buttons-div {
    padding: 0px 60px;
  }

}

/* ------------------------------------- education section ------------------------------------- */
.education-div-main-component {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 50px;
}

@media screen and (max-width: 576px) {
  .education-div-main-component {
    grid-template-columns: 1fr;
  }
}

.carousel {
  position: relative;
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 45px;
  height: 45px;
}

.carousel .pre {
  position: absolute;
  top: 0;
  right: 56%;
  height: 45px;
  text-shadow: 2px 2px 2px #e67e22;
}

.carousel .change_outer {
  top: 0;
  left: 46%;
  text-align: left;
  height: 45px;
  overflow: hidden;
}

.carousel .change_outer .change_inner {
  -webkit-animation: rotate 8s ease-in-out infinite;
  animation: rotate 8s ease-in-out infinite;
}

.carousel .change_outer .element {
  display: block;
  font-weight: 700;
  text-shadow: 2px 2px 2px #e67e22;
}

@-webkit-keyframes rotate {

  0%,
  20% {
    transform: translateY(0);
  }

  25%,
  45% {
    transform: translateY(-45px);
  }

  50%,
  70% {
    transform: translateY(-90px);
  }

  75%,
  95% {
    transform: translateY(-135px);
  }

  100% {
    transform: translateY(-180px);
  }
}

@keyframes rotate {

  0%,
  20% {
    transform: translateY(0);
  }

  25%,
  45% {
    transform: translateY(-45px);
  }

  50%,
  70% {
    transform: translateY(-90px);
  }

  75%,
  95% {
    transform: translateY(-135px);
  }

  100% {
    transform: translateY(-180px);
  }
}



/* skill card div */
.skill-cards-div-flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  row-gap: 46px;
}

@media screen and (max-width:992px) {
  .skill-cards-div-flex {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width:576px) {
  .skill-cards-div-flex {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
}

/* ---------------------------------------------------------------- */
/* body {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  background-color: #212121;
} */

section {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
}

.scrollssss {
  display: flex;
  width: 100%;
  box-shadow: 0 8px 16px rgba(#000000, 0.5);

  div {
    font-weight: 900;
    font-size: 4em;
    text-transform: uppercase;
    white-space: nowrap;
    color: #212121;
    background-color: #fff;
    animation: animate 40s -40s linear infinite;
    will-change: transform;

    &:nth-child(2) {
      animation: animate2 40s -20s linear infinite;
    }
  }

  span {
    -webkit-text-stroke: 2px #000000;
    color: #ffffff;
  }
}

.text1 {
  transform: rotate(30deg) translate(-200px, 200px);
}

.text2 {
  transform: rotate(-25deg) translate(-200px, 120px);
}

.text3 {
  transform: rotate(25deg) translate(-200px, 300px);
}

.text3 {
  transform: rotate(-2deg) translate(-200px, -150px);
}

.text5 {
  transform: rotate(5deg) translate(-200px, 250px);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-200%);
  }
}

.contacte-head-text {
  color: #424242;
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 60px;
}



.the-best-decision-section {
  text-align: center;
  margin-top: 120px;
  margin-bottom: 30px;
  padding: 0px 230px;
}

.subscribe-the-best-decision-section {
  margin: 100px 0px !important;
}

.the-best-decision-section h1 {
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

.the-best-decision-section h1 span {

  color: #ACACAC;
  transition: all 0.3s linear;


}

.the-best-decision-section h1 span:hover {
  color: #424242;
  transition: all 0.3s linear;
  cursor: default;
}

@media screen and (max-width:992px) {
  .the-best-decision-section {
    padding: 0px 60px;
  }

  .the-best-decision-section h1 {
    font-size: 22px;
  }

  .contacte-head-text {
    font-size: 18px;
  }

  .subscribe-the-best-decision-section {
    margin: 40px 0px !important;
  }
}

@media screen and (max-width:576px) {
  .the-best-decision-section {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0px 30px;
  }

  .contacte-head-text {
    font-size: 16px !important;
    margin-bottom: 22px;
  }

  .the-best-decision-section h1 {
    font-size: 15px;
  }

  .subscribe-the-best-decision-section {
    margin: 0px 0px !important;
  }
}